export const menu = {
  name: "Huvudmeny",
  items: [
    {
      title: "Boka tid",
      route: "https://boka.frenda.se/344804",
    },
    {
      title: "Behandlingar",
      route: "/behandlingar",
    },
    // {
    //   title: "Hitta hit",
    //   route: "/#kontakt",
    // },
    // {
    //   title: "Öppettider",
    //   route: "/#kontakt",
    // },
    {
      title: "Priser",
      route: "/priser",
    },
    {
      title: "Vi som jobbar här",
      route: "/personal",
    },
    {
      title: "Om oss",
      route: "/#om-oss",
    },
    {
      title: "Kontakt",
      route: "/kontakt",
    },
  ],
}
