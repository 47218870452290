import React from "react"

export default function Button({ url, text }) {
  return (
    <div
      href={url}
      className="inline-block bg-secondary text-primary px-3 py-2 md:px-4 md:py-3  rounded-md uppercase font-bold text-xl"
    >
      {text}
    </div>
  )
}
