import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Hero from "./hero"
import Footer from "./footer"

const Layout = ({ children, isHome }) => {
  return (
    <div className="bg-white text-primary antialiased min-h-screen">
      <div
        className={`${
          isHome ? "md:h-screen h-[50vh]" : ""
        } flex flex-col text-white`}
      >
        <div className="relative z-40">
          <Header />
        </div>
        {isHome && (
          <div id="hero" className="flex h-full  overflow-hidden  relative">
            <Hero />
          </div>
        )}
      </div>
      <div className="flex-grow ">
        <main className="">{children}</main>
      </div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
