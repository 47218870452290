import React, { useState } from "react"
import { Slant as Hamburger } from "hamburger-react"
import Headroom from "react-headroom"
import Link from "./link"
import LogoAlt from "./logo-alt"
import { menu } from "../data/menu"

import LockScroll from "../lockScroll"

export default function Header() {
  const [isOpen, setOpen] = useState(false)
  const handleClick = (event, route) => {
    if (route.includes("#")) {
      setOpen(false)
    }
  }
  return (
    <Headroom>
      <nav className="relative z-40 bg-secondary text-primary ">
        <div className="flex md:flex-row w-full justify-between p-6 md:p-6 items-center">
          <div className="">
            <div className="w-48 md:w-64">
              <Link to="/">
                <LogoAlt />
              </Link>
            </div>
          </div>

          <div className="hidden lg:flex flex-row items-center justify-end flex-1 text-right space-x-6">
            {menu.items.map((post, i) => {
              return <MenuItem key={i} post={post} />
            })}
          </div>
          <div className="inline-block lg:hidden z-20 relative left-[8px]">
            <Hamburger toggled={isOpen} toggle={setOpen} />
          </div>
          {isOpen && (
            <>
              <LockScroll />
              <div className="absolute inset-0 top-0 left-0 z-10 h-screen overflow-x-hidden  ">
                <div className="flex h-full flex-col items-center justify-center bg-secondary">
                  <div className="flex flex-col gap-8 text-4xl">
                    {menu.items.map((item, i) => {
                      const route = item.route
                      return (
                        <Link
                          fade
                          direction="left"
                          duration={1}
                          key={i}
                          to={item.route}
                          onClick={event => handleClick(event, route)}
                        >
                          {item.title}
                        </Link>
                      )
                    })}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </nav>
    </Headroom>
  )
}

function MenuItem({ post }) {
  return (
    <Link
      to={post.route}
      className="tracking-wide text-xl transition-all hover:opacity-60 duration-200 whitespace-nowrap"
      activeClassName="opacity-60"
    >
      {post.title}
    </Link>
  )
}
