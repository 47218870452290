import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Button from "./button"
import Link from "./link"
import { Fade } from "react-reveal"
import { useStaticQuery, graphql } from "gatsby"

export default function Hero() {
  return (
    <section className="w-full h-full  bg-secondary">
      {useHasMounted && (
        <Fade>
          <div className="relative flex flex-col px-4 pb-4 md:px-6 md:pb-6 md:flex-row  h-full overflow-hidden ">
            <div className="relative h-full w-full  order-last md:order-first">
              <StaticImage
                src="../images/2entre.jpg"
                formats={["jpg"]}
                className="h-full w-full object-cover"
                aspectRatio={1.6}
                placeholder="dominantColor"
              />
            </div>
          </div>
          <div className="absolute md:w-full h-full top-0 flex items-end justify-center md:justify-end z-10 text-white text-7xl">
            <CallToAction />
          </div>
        </Fade>
      )}
    </section>
  )
}

function CallToAction() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 17 }) {
        informationStartsida {
          textSidhuvud
        }
      }
    }
  `)
  const heroText = data.wpPage.informationStartsida.textSidhuvud
  return (
    <div className="hidden md:flex  bg-primary text-secondary flex-col justify-center p-8 m-8 md:p-12 md:m-12 rounded-md">
      <div className=" text-center md:text-left lg:text-2xl text-xl">
        <div className="space-y-4">
          <div
            dangerouslySetInnerHTML={{ __html: heroText }}
            className="wp-content"
          />

          <p className="pt-2">
            <Link to="https://boka.frenda.se/344804" className="">
              <Button text="Boka tid" />
            </Link>
          </p>
          {/* <p className=" font-semibold"></p> */}
        </div>
        <div></div>
      </div>
    </div>
  )
}

function useHasMounted() {
  const [hasMounted, setHasMounted] = React.useState(false)
  React.useEffect(() => {
    setHasMounted(true)
  }, [])
  return hasMounted
}
